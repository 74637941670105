<template>
  <div class="field-item">
    <div class="field-item__label">
      {{ itemVal.label }}
    </div>
    <textInput
      :typeInput="'text'"
      :value.sync="itemVal.value"
      :link="link"
      :customIcon="customIcon"
      @handleClickIcon="handleClickIcon"
    >
    </textInput>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';

export default {
  name: 'LinkField',
  components: {
    textInput,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      link: true,
      customIcon: 'ub-icon-edit-profile',
    };
  },
  computed: {
    itemVal: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
  },
  methods: {
    handleClickIcon() {
      this.link = !this.link;
      this.customIcon = this.link ? 'ub-icon-edit-profile' : 'ub-icon-check-mark-select';
    },
  },
};
</script>

<style lang="scss">
  @import "../../sass/_variables.scss";
  @import "../../sass/fields";
</style>
